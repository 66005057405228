import React from 'react';
import './Header.css';

const Header = () => (
  <div className="app__header  app__wrapper  section__padding" id="home">
    <div className="app__wrapper_info">
      <h1 className="app__header-h1">ZFS</h1>
      <p className="mystyle" style={{ margin: '2rem 0' , color: '#000080'}}  >NGOKUSEBENZISANA SONCEDANA</p>
    </div>

  </div>
);

export default Header;
