import React from 'react';
import { FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="mystyle" style={{ color: '#000080' }}>1 Sibongile Building Highway, Mdanstane, East London, Eastern Cape</p>
        <p className="mystyle" style={{ color: '#000080' }} >#disclaimer: Only branch location.</p>
        <p className="p__cormorant" style={{ color: '#000080', margin: '3rem 0' }}>Open Office Hours</p>
        <p className="mystyle" style={{ color: '#000080' }} >Mon - Fri: 08:00  - 16:30 </p>
        <p className="p__cormorant" style={{ color: '#000080', margin: '3rem 0' }}>Funeral Work Hours</p>
        <p className="mystyle" style={{ color: '#000080' }} >Mon - Sun: 05:00  - 16:00 </p>
       
      </div>

      <div className="app__footer-links_icons">
      <p className="p__cormorant" style={{ color: '#000080', margin: '3rem 0' }}>Social Media Platforms</p>
          <a href="https://www.facebook.com/profile.php?id=61558661847924" target="_blank" rel="noopener noreferrer">
            <FiFacebook style={{ color: '#000000', margin: '3rem 0' }}/>
            <div>
              <p className='mystyle'>Facebook</p> 
            </div>
          </a>
          <a href="https://www.instagram.com/zusivefs" target="_blank" rel="noopener noreferrer">
            <FiInstagram style={{ color: '#000000', margin: '3rem 0' }}/>
            <div>
              <p className='mystyle'>Instagram</p> 
            </div>
          </a>
          <a href="https://www.youtube.com/channel/UCtL1XvMuEQqc7mIWZNq3yCA" target="_blank" rel="noopener noreferrer">
            <FiYoutube style={{ color: '#000000', margin: '3rem 0' }}/>
            <div>
              <p className='mystyle'>Youtube</p> 
            </div>
          </a>
        </div>

    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;