import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Founders.css';

const Founders = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.founders} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Founder's words" />
      <h1 className="headtext__cormorant">What we believe in!</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="mystyle">At Zusive , we understand that saying goodbye is never easy. We founded this platform with a deep commitment to providing solace, guidance, and support during your time of need.
                Every life is a story, and it's our honor to help you celebrate and honor the life of your loved one. We offer a place of compassion, where memories are cherished, and legacies are honored.
          </p>
        </div>
        <p className="mystyle">
            May the love and memories you hold dear bring comfort during this tender time. Know that we are here for you, offering our heartfelt support as you navigate this journey of remembrance and healing. 
            with sympathy and warmth,
        </p>
      </div>

      <div className="app__chef-sign">
        <p>Zukiswa Sam </p>
        <p className="mystyle">Founders'</p>
        
      </div>
    </div>
  </div>
);

export default Founders;