import React from 'react';
import { FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app_bg section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">1 Sibongile Building Highway, Mdantsane</p>
        <p className="p__opensans">+27 83 317 1935</p>
        <p className="p__opensans">+27 82 662 8026</p>
        <p className="p__opensans">
          <a href="mailto:zusivef@gmail.com">zusivef@gmail.com</a>
        </p>
        <p className="p__opensans">
          <a href="mailto:zukiswasam5@gmail.com">zukiswasam5@gmail.com</a>
        </p>
        <p className="p__opensans">
          <a href="mailto:sam.bullie@gmail.com">sam.bullie@gmail.com</a>
        </p>
        

      </div>

      <div className="app__footer-links_logo">
        <img src={images.zusive} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.candle} alt="candle" className="candle__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.facebook.com/profile.php?id=61558661847924" target="_blank" rel="noopener noreferrer">
            <FiFacebook />
          </a>
          <a href="https://www.instagram.com/zusivefs" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
          </a>
          <a href="https://www.youtube.com/channel/UCtL1XvMuEQqc7mIWZNq3yCA" target="_blank" rel="noopener noreferrer">
            <FiYoutube />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext"> Funeral Working Hours</h1>
        <p className="p__opensans">Monday-Sunday:</p>
        <p className="p__opensans">05:00  - 16:30 </p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2006 Zusive Funerals. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
