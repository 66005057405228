import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';
 

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.candle} alt="about_candle" className="candle__img" />
        <p className="p__opensans">
          Zusive Funeral services envisage a prosperous, down to earth business driven by the need serve its people with dignity and not profit driven as we take pride in the service and quality we provide.
          Our mission is to cultivate robust communication channels, foster goodwill, and facilitate mutual improvements within our community. We are committed to upholding the highest professional ideals and ethical standards among our members. Our goal is to actively represent and safeguard the collective interests of our members, 
          ensuring their voices are heard and their concerns addressed.
          We value constructive contributions and actively listen to feedback, offering professional advice and unwavering support to bereaved families and friends during challenging times. Additionally, we prioritize the welfare of the public, striving to create a safe and supportive environment for all. 
      </p>
       
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">History</h1>
        <img src={images.candle} alt="about_candle" className="candle__img" />
        <p className="p__opensans">
          Zusive Funeral Services, founded in 2006 by sisters Veliswa and Zukiswa is a family-owned business known for its personal approach. 
          Since the beginning, we've understood the challenges of our industry, especially concerning our diverse clientele.
          At Zusive, we help families make relevant decisions and handle practical arrangements with dignity and respect as they bid farewell to their loved ones. 
          Our commitment to managing every detail with sensitivity and empathy has earned us a reputation for excellence. With personalized supervision at each step, we ensure you receive the best care during this difficult and often stressful time. We pride ourselves on being gentle, personal, family-oriented, respectful, and considerate.
        </p>
        
      </div>
    </div>
  </div>
);

export default AboutUs;