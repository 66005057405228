import bg from '../assets/bg.png';
import bg2 from '../assets/bg5.svg';
import founders from '../assets/founders.jpg';
import G from '../assets/Z.png';
import gallery01 from '../assets/gallery01.jpg';
import gallery02 from '../assets/gallery02.jpg';
import gallery03 from '../assets/gallery03.jpg';
import gallery04 from '../assets/gallery04.jpg';
import gallery05 from '../assets/gallery05.jpg';
import gallery06 from '../assets/gallery06.jpg';
import gallery07 from '../assets/gallery07.jpg';
import gallery08 from '../assets/gallery08.jpg';
import gallery09 from '../assets/gallery09.jpg';
import gallery10 from '../assets/gallery10.jpg';
import gallery11 from '../assets/gallery11.jpg';
import gallery12 from '../assets/gallery12.jpg';
import gallery13 from '../assets/gallery13.jpg';
import gallery14 from '../assets/gallery14.jpg';
import gallery15 from '../assets/gallery15.jpg';
import gallery16 from '../assets/gallery16.png';
import gallery17 from '../assets/gallery17.jpg';
import knife from '../assets/about.png';
import logo from '../assets/logo.png';
import menu from '../assets/deco.png';
import overlaybg from '../assets/overlaybg.png';
import candle from '../assets/candle.png';
import zusivelog from '../assets/zusive.logo.svg.png';
import findus from '../assets/whitefuneral.jpg';
import carboot from '../assets/buti.jpg';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import quote from '../assets/quote.png';
import zusive from '../assets/zusive fs.png';

export default {
  bg,
  bg2,
  founders,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  knife,
  logo,
  menu,
  overlaybg,
  candle,
  zusivelog,
  findus,
  carboot,
  award01,
  award02,
  award03,
  award05,
  quote,
  zusive,
};
