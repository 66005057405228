import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Principle & Integrity" />
      <h1 className="headtext__cormorant">Service&apos;s Offered</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Our Services</p>
        <div className="app__specialMenu_menu_items">
          {data.visions.map((visions, index) => (
            <MenuItem key={visions.title + index} title={visions.title} price={visions.price} tags={visions.tags} />
          ))}
        </div>
      </div>
 
      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Services </p>
        <div className="app__specialMenu_menu_items">
          {data.missions.map((missions, index) => (
            <MenuItem key={missions.title + index} title={missions.title} price={missions.price} tags={missions.tags} />
          ))}
        </div>
      </div>
    </div>

    
  </div>
);

export default SpecialMenu;

