import images from './images';

const visions = [
  {
    title: 'Funeral Covers',
    tags: '',
  },
  {
    title: 'Mortuary Services',
    tags: '',
  },
  {
    title: 'Tombstones',
    tags: '',
  },
  {
    title: 'Mourners Transport',
    tags: '',
  },
  {
    title: 'Provide Coffins, Caskets and Domes',
    tags: '',
  },
  {
    title: 'Floral arrangement',
    tags: '',
  },
];

const missions = [
  {
    title: 'Decorations',
    tags: '',
  },
  {
    title: 'Tent, Chair, and Toilet Rentals',
    tags: '',
  },
  {
    title: 'Video and Photography Coverage',
    tags: '',
  },
  {
    title: 'Catering',
    tags: '',
  },
  {
    title: 'Equipment Hire (Catering and Funeral)',
    tags: '',
  },
  {
    title: 'Program Design and Priniting',
    tags: '',
  },
 
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'Ngalwa',
    subtitle: '"Zusive Funeral Services provided such compassionate and professional support during our time of loss. Their attention to detail and caring staff made a difficult time more manageable."',
  },
  {
    imgUrl: images.award02,
    title: 'Khumbuleni',
    subtitle: '"I highly recommend Zusive Funeral Services for their exceptional care and understanding. They went above and beyond to ensure every aspect of the funeral service was handled with dignity and respect."',
  },
  {
    imgUrl: images.award03,
    title: 'Nkihlana',
    subtitle: '"Zusive Funeral Services exceeded our expectations in every way. From the beautiful arrangements to the thoughtful guidance, they truly honored our loved ones memory with grace and compassion."',
  },
  {
    imgUrl: images.award05,
    title: 'Mahlasela',
    subtitle: '"Choosing Zusive Funeral Services was the best decision we made during a challenging time. Their team expertise and empathy made a difficult process easier to navigate, and we are grateful for their support."',
  },
];

export default { visions, missions, awards };